// eslint-disable-next-line no-restricted-syntax -- old one, can stay for now
'use server'

import { getServerSession } from 'next-auth'

import { options } from '@/app/api/auth/[...nextauth]/options'
import { appConfig } from '@/shared/appConfigServer'

/**
 * Log user out of ForgeRock CIAM.
 */
export const federatedLogOut = async (): Promise<boolean> => {
  try {
    const endSessionUrl = appConfig.ciam.signOutUrl
    const session = await getServerSession(options)

    if (!session || !session.user.idToken) {
      // NOTE: If we have no session or idToken, then we don't need to logout from forgerock
      return true
    }

    const endSessionParams = new URLSearchParams({ id_token_hint: session.user.idToken })

    const response = await fetch(`${endSessionUrl}?${endSessionParams}`, {
      headers: {
        Authorization: `Bearer ${session.user.accessToken}`,
      },
    })

    return response.ok
  } catch {
    return false
  }
}
