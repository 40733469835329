// eslint-disable-next-line no-restricted-syntax -- old one, can stay for now
'use server'

import tracer from 'dd-trace'

import type { BaseError } from './errorClasses' // Import your base error class

/**
 * Log an error to Datadog APM on the server.
 * This function should only be used in a server context.
 */
export const logErrorToAPM = async (error: BaseError) => {
  // Initialize tracer for error logging
  tracer.trace(error.code, (span) => {
    span.setTag('error', true)
    span.setTag('error.id', error.id) // Unique error ID
    span.setTag('error.code', error.code) // Custom error code
    span.setTag('error.message', error.message) // Error message
    span.setTag('error.name', error.name) // Error name
    span.setTag('error.additionalData', JSON.stringify(error.additionalData)) // Additional data
    span.setTag('error.stack', error.stack) // Error stack trace (if available)

    // Finish the span to send to APM
    span.finish()
  })
}
